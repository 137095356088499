<template>
  <ApolloQuery
    :query="require('@/graphql/queries/AllFaqPopular.gql')"
    @result="doneLoadingFaq"
  >
    <template slot-scope="{ result: { loading, error } }">
      <div class="lsg-faq">
        <div class="main__header">
          <div class="main__header__item left"></div>
          <div class="main__header__item">
            <h1>{{ title }}</h1>
          </div>
          <div class="main__header__item right"></div>
        </div>
        <div v-if="error" class="messages apollo error">
          <div class="messages__status error">{{ error.message }}</div>
        </div>
        <div v-if="loading && !error" class="messages__status loading">Идёт загрузка данных...</div>
        <div v-if="!error && !loading" class="main__content">
          <div class="faq__bar">
            <label class="faq__bar__input">
              <i>
                <SearchIco/>
              </i>
              <input type="text" v-model="modelSearch" class="hs__input" placeholder="Поиск вопросов">
              <div class="hs__drop faq__bar__search" v-if="searchInput.length > 2" v-click-outside="vcoConfig">
                <div class="hs__section">
                  <ul class="hs__section__list">
                    <li class="hs__section__item__warning" v-if="searchResult.length === 0">Нет результатов по запросу</li>
                    <li v-for="(elem, index) in searchResult" :key="index"><a class="hs__section__item__link" @click="scrollToQuestion(`${elem.question}`)">{{ elem.question }}</a></li>
                  </ul>
                </div>
              </div>
            </label>
            <div class="faq__bar__links">
              <router-link :to="{ name: 'support' }" class="btn">
                <span>МОИ ОБРАЩЕНИЯ</span>
              </router-link>
              <router-link :to="{ name: 'question' }" class="btn">
                <i>
                  <PlusIco/>
                </i>
                <span>ОБРАЩЕНИЕ</span>
              </router-link>
            </div>
          </div>
          <div class="faq__nav">
            <a @click="scroll(`${elem.theme}`)" :href="`#${elem.theme}`" class="btn btn--gray" v-for="(elem, index1) in faq" :key="index1">{{ elem.theme }}</a>
          </div>
          <div class="faq__list">
            <div :id="elem.theme" class="faq__theme" v-for="(elem, index1) in faq" :key="index1">
              <h2>{{ elem.theme }}</h2>
              <div class="faq__item" @click.self="item.expanded = !item.expanded" v-for="(item, index2) in elem.list" :key="index2">
                <i class="faq__item__state">
                  <PlusIco v-if="!item.expanded"/>
                  <MinusIco v-else/>
                </i>
                <h3 @click.prevent="item.expanded = !item.expanded" :id="item.question" class="faq__item__question">{{ item.question }} <span v-if="item.pay"><RubleIco /></span></h3>
                <div class="faq__item__answer" v-if="item.expanded" v-html="item.answer"></div>
              </div>
            </div>
          </div>
          <div class="scrollToTop" @click="scrollToTop">
            <svg width="50" height="50" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 6L8 10L12 6" stroke="#FF8000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
        </div>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
import SearchIco from '@/components/svg/search'
import PlusIco from '@/components/svg/plus'
import MinusIco from '@/components/svg/minus'
import RubleIco from '@/components/svg/Ruble'

export default {
  name: 'Faq',
  components: {
    MinusIco,
    PlusIco,
    RubleIco,
    SearchIco
  },
  data () {
    return {
      title: 'Вопросы и ответы',
      links: [
        {
          name: 'Главная',
          route: 'home'
        },
        {
          name: 'Часто задаваемые вопросы'
        }
      ],
      vcoConfig: {
        middleware: event => {
          return !event.target.classList.contains('hs__input')
        },
        handler: () => {
          this.searchInput = ''
        },
        events: ['dblclick', 'click'],
        isActive: true
      },
      defaultFaq: [],
      faq: [],
      searchInput: '',
      searchResult: []
    }
  },
  methods: {
    doneLoadingFaq ({ data, loading }) {
      this.defaultFaq = data.allFaqPopular
      let themes = data.allFaqPopular.map(elem => {
        return elem.theme
      })
      themes = [...new Set(themes)]
      let obj = themes.map(elem => (
        {
          theme: elem,
          list: []
        }
      ))
      data.allFaqPopular.forEach(faqElem => {
        obj.forEach(objElem => {
          if (faqElem.theme === objElem.theme) {
            objElem.list.push(
              {
                expanded: false,
                question: faqElem.question,
                answer: faqElem.answer,
                pay: faqElem.pay
              }
            )
          }
        })
      })
      this.faq = obj
    },
    scroll (value) {
      let coordinates = document.getElementById(value).getBoundingClientRect()
      scrollTo({ top: coordinates.top - 100, behavior: 'smooth' })
    },
    scrollToTop () {
      scrollTo({ top: 0, behavior: 'smooth' })
    },
    scrollToQuestion (value) {
      let coordinates = document.getElementById(value).getBoundingClientRect()
      scrollTo({ top: coordinates.top - 150, behavior: 'smooth' })

      this.faq.forEach(elem => {
        elem.list.forEach(item => {
          if (item.question === value) {
            item.expanded = true
          }
        })
      })
    }
  },
  computed: {
    modelSearch: {
      get () {
        return this.searchInput
      },
      set (value) {
        this.searchInput = value
        const sortFaq = this.defaultFaq.map(elem => {
          if (elem.question.toLowerCase().indexOf(value.toLowerCase()) !== -1) return elem
        })
        const newSortFaq = sortFaq.filter(i => i)
        this.searchResult = newSortFaq
      }
    }
  }
}
</script>

<style lang="stylus">
.hs__section__item__warning {
  padding-left 20px !important
}

.hs__section__item__link {
  cursor pointer
}

.scrollToTop
  position fixed
  bottom 70px
  right 50px
  height 50px
  width 50px
  border-radius 50%
  z-index 3
  cursor pointer
  display flex
  justify-content center
  align-items center

  > svg
    transform-origin: center center
    transform rotate(180deg)

.faq
  &__bar
    display flex
    justify-content space-between
    align-items center
    flex-wrap wrap

    &__search {
      +below(768px) {
        position absolute
        absolute left bottom
        left auto
        width 100%
        max-width 500px
        min-width auto
      }
      > .hs__section {
        padding-top 20px

        &__list li a {
          cursor pointer !important
        }
      }
    }

    +below(1040px)
      justify-content center

    &__links
      display flex
      justify-content space-between
      width 400px
      margin-bottom 20px

      +below(440px)
        height 100px
        flex-direction column
        align-items center
        justify-content space-between

    &__input
      max-width 500px
      width 100%
      margin 0 0 20px 0
      position relative

      i
        display flex
        align-items center
        absolute right 16px top bottom

  &__nav
    display flex
    min-height 60px
    flex-direction row
    justify-content flex-start
    flex-wrap wrap
    margin 10px 60px

    +below(440px)
      justify-content center

    > .btn
      height 40px !important
      margin-bottom 20px
      margin-right 10px

  &__list
    display flex
    flex-direction column
    align-items flex-start
    margin -8px 0
    padding 10px 60px

    +below(470px)
      padding 10px 10px

  &__theme
    display flex
    width 100%
    flex-direction column
    align-items flex-start

    &:not(:last-child)
      margin-bottom 30px

  &__item
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    background: $white;
    border: 1px solid rgba($darkgray, 0.3);
    box-sizing: border-box;
    border-radius: 10px;
    padding 40px 80px 40px 40px
    width 100%
    margin 8px 0
    position relative
    cursor pointer

    &:hover
      box-shadow: 0 10px 30px rgba($darker, 0.17);

    &__state
      absolute right 40px top 40px bottom
      width 24px
      height 24px
      display flex
      align-items center
      justify-content center
      flex-shrink 0

      svg
        width 100%
        height 100%

        path
          stroke $c7A

    &__question
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      margin 0
      color $orange

      +below(470px)
        font-size 14px

      +below(375px)
        font-size 12px

    &__pay
      width 100%
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      margin-top 20px
      color $dark
      white-space pre-wrap

    &__answer
      width 100%
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      margin-top 20px
      color $dark
      white-space pre-wrap

      > a
        color $orange
        z-index 2

        &:hover
          text-shadow 1px 1px 2px $orange

      +below(470px)
        font-size 14px

      +below(375px)
        font-size 12px

      ^[0]:hover
        color $dark

  &__answer
    color $black
</style>
